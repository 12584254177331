export const emailFormat = {
    getHtmlStr(...params) {

        const {
            name,
            mobile,
            email,
            numberOfClients,
            amount,
            preferredStragedyPlatform,
            strategyRequirement,
            stragedyTimeSpan,
            tradeInstrument,
            longEntryCondition,
            longExitCondition,
            shortEntryCondition,
            shortExitCondition,
            orderPlacementCondition,
            paymentStatus,
            paymentId,
            orderId,
            budget,
        } = params[0]
        return "<div>Name:{name},</div><div>Mobile:{mobile},</div><div>email:{email},</div><div>Payment Status: {paymentStatus},</div><div>Payment Id: {paymentId},</div><div>Order Id: {orderId},</div><div>Payment Amount: {amount},</div><div>number Of Clients:{numberOfClients},</div><div>Preferred Strategy Platform:{preferredStragedyPlatform},</div><div>Strategy Requirement:{strategyRequirement},</div><div>Strategy TimeSpan:{stragedyTimeSpan},</div><div>tradeInstrument:{tradeInstrument},</div><div>longEntryCondition:{longEntryCondition},</div><div>longExitCondition:{longExitCondition},</div><div>shortEntryCondition:{shortEntryCondition},</div><div>shortExitCondition:{shortExitCondition},</div><div>orderPlacementCondition:{orderPlacementCondition},</div><div>budget:{budget}</div>".split("{name}").join(name).split("{mobile}").join(mobile).split("{email}").join(email).split("{numberOfClients}").join(numberOfClients).split("{preferredStragedyPlatform}").join(preferredStragedyPlatform).split("{strategyRequirement}").join(strategyRequirement).split("{stragedyTimeSpan}").join(stragedyTimeSpan).split("{tradeInstrument}").join(tradeInstrument).split("{longEntryCondition}").join(longEntryCondition).split("{longExitCondition}").join(longExitCondition).split("{shortEntryCondition}").join(shortEntryCondition).split("{shortExitCondition}").join(shortExitCondition).split("{orderPlacementCondition}").join(orderPlacementCondition).split("{budget}").join(budget).split("{paymentStatus}").join(paymentStatus).split("{paymentId}").join(paymentId).split("{orderId}").join(orderId).split("{amount}").join(amount)
    },
    subject: "MyCoder Query",
    recipentEmail: "support@algoji.com",
}