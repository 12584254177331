import { reactive } from "@vue/composition-api";
 
export const inputFields =  reactive(
  // {
  //   name: "",
  //   mobile: "",
  //   email: "",
  //   tnc: false,
  //   numberOfClients: 1,
  //   preferredStragedyPlatform: "",
  //   strategyRequirement: "",
  //   stragedyTimeSpan: "",
  //   tradeInstrument: "",
  //   longEntryCondition: "",
  //   longExitCondition: "",
  //   shortEntryCondition: "",
  //   shortExitCondition: "",
  //   orderPlacementCondition: "",
  //   budget: 1,

  // }
  {
    name : "",
    mobile: "",
    email: "",
    tnc: false,
    numberOfClients: 1,
    amount:0 ,
    preferredStragedyPlatform: "TradingView",
    strategyRequirement: "Indicators/Price Action",
    stragedyTimeSpan: "One-minute to 15-minute",
    tradeInstrument: "Index Futures",
    longEntryCondition: "",
    longExitCondition: "",
    shortEntryCondition: "",
    shortExitCondition: "",
    orderPlacementCondition: "",
    paymentStatus:"",
    paymentId:"",
    orderId:"",
    budget: 10000,
  }
  )


