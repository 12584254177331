<template>
  <div class="mainContainer">
    <div class="meetupFormHeaderContainer">
      <!-- <div class="logoContainer">
        <img
          class="logoImg"
          src="https://algoji.com/wp-content/uploads/2020/03/logo1.png"
          alt=""
        />
      </div> -->
      <div class="headingTextContainer">
        <h1 class="text-success registerFormHeading" v-text="data.heading"></h1>
      </div>
    </div>
    <div class="formAndButtonContainer">
      <div style="" class="meetUpFormContainer">
        <form class="registerForm">
          <div class="stepForm" v-show="data.currentStepIndex == 1">
            <div class="formRow">
              <div class="labelContainer">
                <label for="">Name *</label>
              </div>
              <div class="inputContainer">
                <input
                  type="text"
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.name"
                />
              </div>
            </div>
            <div class="formRow">
              <div class="labelContainer"><label for="">Phone *</label></div>
              <div class="inputContainer">
                <input
                  type="tel"
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.mobile"
                />
              </div>
            </div>
            <div class="formRow">
              <div class="labelContainer"><label for="">Email *</label></div>
              <div class="inputContainer">
                <input
                  type="email"
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.email"
                />
              </div>
            </div>
            <div class="formTextContainer formRow">
              <p>
                MyCoder consultation is done via scheduled remote screen sharing
                and VoIP call. We will send you an email with link to join the
                consultation call, based on your availability. Phone support may
                not be available.
              </p>
            </div>
            <div class="formRow">
              <div class="labelContainer">T&amp;C*</div>
              <div class="inputContainer">
                <input
                  type="checkbox"
                  class="form-control mx-3"
                  v-model="inputFields.tnc"
                />
                <label for=""
                  >I acknowledge to have read
                  <a
                    href="https://algoji.com/mycoder/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >FAQs and Non Disclosure Agreement</a
                  >. I acknowledge that Rs. 99 fee to be paid for this
                  consultation is non-refundable.</label
                >
              </div>
            </div>
          </div>
          <div class="stepForm" v-show="data.currentStepIndex == 2">
            <div class="formRow">
              <div class="labelContainer">
                <!-- <label for="">Multi-client or Single Client * </label> -->
                <label for="">Number of Clients * </label>
              </div>
              <div class="inputContainer">
                <input
                  class="form-control mx-3"
                  type="number"
                  min="1"
                  v-model.number="inputFields.numberOfClients"
                />
              </div>
            </div>
            <div class="formRow">
              <div class="labelContainer">
                <label for="">Preferred Strategy Platform *</label>
              </div>
              <div class="inputContainer">
                <select
                  name=""
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.preferredStragedyPlatform"
                  id=""
                >
                  <option value="TradingView">TradingView</option>
                  <option value="Excel/Python">Excel/Python</option>
                  <option value="Amibroker">Amibroker</option>
                  <option value="MT4/MT5">MT4/MT5</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div class="formRow">
              <div class="labelContainer">
                <label for="">Strategy Requirement * </label>
              </div>
              <div class="inputContainer">
                <select
                  name=""
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.strategyRequirement"
                  id=""
                >
                  <option value="Indicators/Price Action">
                    Indicators/Price Action
                  </option>
                  <option value="Option Greeks">Option Greeks</option>
                  <option value="Statistical Studies">
                    Statistical Studies
                  </option>
                </select>
              </div>
            </div>
            <div class="formRow">
              <div class="labelContainer">
                <label for="">Strategy TimeFrame * </label>
              </div>
              <div class="inputContainer">
                <select
                  class="form-control mx-3"
                  v-model="inputFields.stragedyTimeSpan"
                >
                  <option value="One-minute to 15-minute">
                    One-minute to 15-minute
                  </option>

                  <option value="Under one-minute">Under one-minute</option>

                  <option value="Above 15-minute">Above 15-minute</option>

                  <option value="Multiple TimeFrame">Multiple TimeFrame</option>
                </select>
              </div>
            </div>
            <div class="formRow">
              <div class="labelContainer">
                <label for="">Instruments to Trade *</label>
              </div>
              <div class="inputContainer">
                <select
                  class="form-control mx-3"
                  v-model="inputFields.tradeInstrument"
                >
                  <option value="Index Futures">Index Futures</option>

                  <option value="Stock Cash Segment">Stock Cash Segment</option>

                  <option value="Index Options">Index Options</option>

                  <option value="MCX Futures">MCX Futures</option>

                  <option value="NSE Currency Futures">
                    NSE Currency Futures
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="stepForm" v-show="data.currentStepIndex == 3">
            <div class="">
              <div class="labelContainer">
                <label for="">Long Entry(LE) Conditions</label>
              </div>
              <div class="inputContainer">
                <textarea
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.longEntryCondition"
                ></textarea>
              </div>
            </div>
            <div class="">
              <div class="labelContainer">
                <label for="">Long Exit(LX) Conditions</label>
              </div>
              <div class="inputContainer">
                <textarea
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.longExitCondition"
                ></textarea>
              </div>
            </div>
            <div class="">
              <div class="labelContainer">
                <label for="">Short Entry(SE) Conditions</label>
              </div>
              <div class="inputContainer">
                <textarea
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.shortEntryCondition"
                ></textarea>
              </div>
            </div>
            <div class="">
              <div class="labelContainer">
                <label for="">Short Exit(SX) Conditions</label>
              </div>
              <div class="inputContainer">
                <textarea
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.shortExitCondition"
                ></textarea>
              </div>
            </div>
            <div class="">
              <div class="labelContainer">
                <label for="">Order Placement Conditions </label>
              </div>
              <div class="inputContainer">
                <textarea
                  class="form-control mx-3"
                  v-model.lazy.trim="inputFields.orderPlacementCondition"
                ></textarea>
              </div>
            </div>
            <div class="formRow">
              <div class="labelContainer">
                <label for="">Your Budget in INR/Rs</label>
              </div>
              <div class="inputContainer">
                <input
                  type="number"
                  min="1"
                  class="form-control mx-3"
                  v-model.lazy.number="inputFields.budget"
                />
              </div>
            </div>
          </div>
          <!-- <div v-if="data.currentStepIndex == data.endStepIndex"><button>Submit</button></div> -->
          <div style="" class="buttonContainer">
            <button
              v-if="isprevBtnDisplay"
              class="btn btn-primary"
              @click="prev"
              type="button"
            >
              Previous
            </button>
            <button
              v-if="isnextBtnDisplay"
              :disabled="!isCurrentStepValid"
              @click="next"
              type="button"
              class="btn btn-primary"
            >
              Next
            </button>
            <button
              :disabled="!isSubmitValid || data.isSubmitDisabled"
              @click="formSubmit"
              class="btn btn-primary"
              v-if="isSubmitBtn"
              type="button"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="meetUpFormFooter"></div>
  </div>
</template>

<script>
import { inputFields } from "../../state/meetupForm/inputFields";
import { validationRegex } from "../../state/validationRegex";
import { emailFormat } from "../../exceptionEmailFormats/meetEmailFormat.js";
import "../../assets/css/Common.css";
import { URLS } from "../../state/URLS.js";
export default {
  name: "MeetUpFormPage",
  data() {
    return {
      URLS,
      validationRegex,
      data: {
        isSubmitDisabled: false,
        heading: "MyCoder Consultation",
        logoSrc: "../../assets/img/logo.png",
        currentStepIndex: 1,
        logId: 0,
        endStepIndex: 3,
        amount: 99,
        successMessage: "Registered Successfully",
      },
      api_base_url: process.env.VUE_APP_API_URL,
      demo_api_base_url: process.env.VUE_APP_DEMO_API_URL,
      admin_base_url: process.env.VUE_APP_ADMIN_API_URL,
      AUTH_DETAILS: {
        API_TOKEN: null,
      },
      inputFields,
    };
  },
  created() {},
  async mounted() {
    await this.fetchFormData();
    this.inputFields.amount  = this.data.amount
    // this.fetchPaymentObj()
    // this.paymentHandler()
  },
  methods: {
    async fetchFormData() {
      const fetchFormDataUrl = `${this.URLS.meetup_demo_base_url}api/forms/Template/algoji`;
      try {
        const formDataResp = await fetch(fetchFormDataUrl, {
          method: "GET",
          redirect: "follow",
        });

        const reqStatusCode = formDataResp.status;

        const formDataObj = await formDataResp.json();

        const formDataValue = formDataObj.data; //Need to check whetheer form data

        if (reqStatusCode != 200) {
          // this.formDetails.isFormLoadedError = true;
          // this.formDetails.formLoadedErrorText = "Something Went Wrong";
          return false;
        }

        if (formDataObj.code != 200) {
          // if(this.refreshFormDataFetchCount > 0 && !formDataObj.data.trim().toLowerCase().includes("Data is not available!".toLowerCase())){

          //             setTimeout(this.fetchFormData , 100)
          //   this.refreshFormDataFetchCount--;
          //   return false ;
          // }
          // this.formDetails.isFormLoadedError = true;
          // this.formDetails.formLoadedErrorText = "Form Not Found";
          return false;
        }
        if (formDataObj.error) {
          // this.formDetails.isFormLoadedError = true;
        }

        // this.formDetails.isFormLoaded = true;
        // this.formDetails.isFormLoadedError = false;

        this.AUTH_DETAILS.API_TOKEN = "Bearer " + formDataValue.Token;
        this.AUTH_DETAILS.ADMIN_API_TOKEN =
          "Bearer " + formDataValue.Admin.Token;
        return formDataValue;
      } catch (e) {
        // this.formDetails.isFormLoaded = true;
        // this.formDetails.isFormLoadedError = true;
        // this.formDetails.formLoadedErrorText = "Something Went Wrong";
        return false;
      }
    },

    async formSubmit() {
      // console.log(JSON.stringify({ insertJson: this.inputFields }));
      this.data.isSubmitDisabled = true;
      const logRespObj = await this.submitLogUrl();
      this.data.logId = logRespObj.data.split(":")[1];
      // console.log({ logId: this.data.logId });

      const paymentObj = await this.fetchPaymentObj();
      // debugger;

      paymentObj.handler = this.paymentHandler;

      const rzp1 = new Razorpay(paymentObj);
      rzp1.open();
      this.data.isSubmitDisabled = false;
    },

    async paymentHandler(...params) {
      params = params[0];
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      this.data.isSubmitDisabled = true ;
      const {
        razorpay_order_id: orderId,
        razorpay_signature: signatureId,
        razorpay_payment_id: paymentId,
      } = params;
      const payloadBodyResp = JSON.stringify({
        orderId,
        signatureId,
        paymentId,
      });

      //  const [orderId,signatureId,paymentId] = ["order_Gyt5KTF6cihjAr" , "40e5836a6959616b662a09217fe5eeaf1aa5bfbdf6a3cf4add9a831f3d821321" , "pay_Gyt5aRYQGBaoPr" ]
      const requestObj = {
        headers: jsonHeader,
        body: JSON.stringify({
          orderId,
          signatureId,
          paymentId,
          isMeetup: true,
        }),
        method: "POST",
        redirect: "follow",
      };

      const paymentVerificationUrl = `${this.URLS.meetup_demo_base_url}/api/forms/PaymentVerification`;
      const responseObj = await fetch(paymentVerificationUrl, requestObj);
      const verificationRespObj = await responseObj.json();
      const isPaymentVerified = verificationRespObj.data;
      this.data.isSubmitDisabled = false;
      if (isPaymentVerified) {
        this.inputFields.orderId = orderId;
        this.inputFields.paymentId = paymentId;
        this.inputFields.paymentStatus = "Success";

        const isPaymentRecorded = await this.updateUserLog({
          orderId,
          signatureId,
          paymentId,
        });

        if (isPaymentRecorded) {
          this.UserDetailToAdmin();
          await this.$swal({
            title: "Success",
            text: this.data.successMessage,
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });

          window.location.reload();
        }
      }
    },
    async updateUserLog(paymentObj) {
      const User = this.inputFields;
      User.paymentSignature = paymentObj.signatureId;
      User.orderId = paymentObj.orderId;
      User.paymentId = paymentObj.paymentId;
      User.paymentStatus = "success";

      const UserStr = JSON.stringify(User);
      const UserPayload = JSON.stringify({
        insertJson: UserStr,
        recordID: this.data.logId,
        isMeetup: true,
      });
      // console.log(UserPayload)
      // return
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const updateUserLogUrl = `${this.URLS.meetup_demo_base_url}/api/forms/UpdatepaymentStatus`;
      const isRegisteredUserUpdatedResp = await fetch(updateUserLogUrl, {
        method: "POST",
        headers: jsonHeader,
        body: UserPayload,
        redirect: "follow",
      });
      const isRegisteredUserUpdatedRespJson = await isRegisteredUserUpdatedResp.json();

      const isLogUpdated = isRegisteredUserUpdatedRespJson.data.includes(
        "Record Updated! ID"
      );
      return isLogUpdated;
    },

    async UserDetailToAdmin() {
      const emailSendUrl = `${this.URLS.meetup_demo_base_url}/api/forms/SendMail`;
      let obj = {};
      // ,${this.inputFields.email}
      const emailStr = emailFormat.getHtmlStr(this.inputFields);
      obj = {
        Recipient: `${emailFormat.recipentEmail}`,
        Subject: emailFormat.subject,
        Sender: "no-reply@apibridge.app",
        Body: emailStr,
        replyToEmail: this.inputFields.email,
        isMeetup: true,
      };
      let  payload = JSON.stringify(obj);
      const headers = this.getJsonHeaders();
      headers.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const ismailSendRespToSupport = await fetch(emailSendUrl, {
        headers,
        method: "POST",
        body: payload,
        redirect: "follow",
      });
      obj.Recipient = this.inputFields.email
      delete obj.isMeetup ;
      delete obj.replyToEmail
      payload = JSON.stringify(obj);
      await fetch(emailSendUrl, {
        headers,
        method: "POST",
        body: payload,
        redirect: "follow",
      });
      return true 
      // const ismailSendRespObj = await ismailSendResp.json();
      // return ismailSendRespObj;
    },

    getJsonHeaders() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },
    async submitLogUrl() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const dataStr = JSON.stringify(this.inputFields);
      const payloadStr = JSON.stringify({
        insertJson: dataStr,
        isMeetup: true,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: payloadStr,
        isMeetup: true,
        redirect: "follow",
      };
      // const submitUserLogUrl = `${this.URLS.meetup_demo_base_url}/api/meetup/InsertInto`
      const submitUserLogUrl = `${this.URLS.meetup_demo_base_url}/api/forms/InsertInto`;

      const logResp = await fetch(submitUserLogUrl, requestOptions);
      return await logResp.json();
    },
    next() {
      if (this.data.currentStepIndex < 3) {
        const isCurrentStepIndexValid = this.isCurrentStepValid;

        if (isCurrentStepIndexValid) this.data.currentStepIndex++;
      }
    },
    async fetchPaymentObj() {
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      const payload = JSON.stringify({
        name: this.inputFields.name,
        email: this.inputFields.email,
        phone: this.inputFields.mobile,
        amount: this.data.amount * 100,
        isMeetup: true,
      });

      // return payload ;
      const requestObj = {
        method: "POST",
        headers: jsonHeader,
        body: payload,
        redirect: "follow",
      };

      const paymentRespUrl = `${this.URLS.meetup_demo_base_url}/api/forms/CreateRazorPayOrder`;
      const paymentObjResp = await fetch(paymentRespUrl, requestObj);
      const paymentObj = await paymentObjResp.json();

      return paymentObj.data;
    },

    _isCurrentStepValid(currentIndex) {
      debugger;
      switch (currentIndex) {
        case 1:
          return this.validateFirstStep;
        case 2:
          return this.validateSecondForm;
        case 3:
          return this.validateThirdForm;
      }
    },

    prev() {
      if (this.data.currentStepIndex > 1) {
        this.data.currentStepIndex--;
      }
    },
  },
  computed: {
    isCurrentStepValid() {
      const currentIndex = this.data.currentStepIndex;
      switch (currentIndex) {
        case 1:
          return this.validateFirstStep;
        case 2:
          return this.validateSecondForm;
        case 3:
          return this.validateThirdForm;
      }
    },
    validateFirstStep() {
      let isFormValid = false;
      if (
        !this.isNameValid ||
        !this.isEmailValid ||
        !this.isPhoneValid ||
        !this.inputFields.tnc
      ) {
        isFormValid = false;
      } else if (
        this.isNameValid &&
        this.isEmailValid &&
        this.isPhoneValid &&
        this.inputFields.tnc
      )
        return true;
      return isFormValid;
    },

    validateSecondForm() {
      let isFormValid = false;

      if (
        this.isNumberOfClientValid &&
        this.isPreferredStragedyPlatformValid &&
        this.isStrategyRequirementValid &&
        this.isTradeInstrumentValid &&
        this.isStrategyTimeFrameValid
      )
        return true;
      return isFormValid;
    },
    // validateThirdForm() {
    //   return this.validateSecondForm && this.validateFirstStep;
    // },
    isSubmitValid() {
      return this.validateSecondForm && this.validateFirstStep;
    },
    isEmailValid() {
      return this.validationRegex.email.test(this.inputFields.email);
    },
    isNameValid() {
      return this.inputFields.name.trim() != "";
    },
    isPhoneValid() {
      return this.validationRegex.mobile.test(this.inputFields.mobile);
    },
    isNumberOfClientValid() {
      return (
        this.inputFields.numberOfClients > 0 &&
        this.inputFields.numberOfClients != undefined &&
        this.inputFields.numberOfClients != null
      );
    },
    isPreferredStragedyPlatformValid() {
      return this.inputFields.preferredStragedyPlatform != "";
    },
    isStrategyRequirementValid() {
      return this.inputFields.strategyRequirement.trim() != "";
    },
    isTradeInstrumentValid() {
      return this.inputFields.tradeInstrument.trim() != "";
    },
    isStrategyTimeFrameValid() {
      return this.inputFields.stragedyTimeSpan.trim() != "";
    },
    isprevBtnDisplay() {
      return this.data.currentStepIndex != 1;
    },
    isnextBtnDisplay() {
      return this.data.currentStepIndex != this.data.endStepIndex;
    },
    isSubmitBtn() {
      return this.data.currentStepIndex == this.data.endStepIndex;
    },
  },
};
</script>

<style scoped>
.mainContainer {
  background-color: rgb(231, 240, 233) !important;
}
.formAndButtonContainer {
  background-color: white;
  /* display: flex;
  align-content: center;
  justify-content: center; */

  margin: 20px 50px;
  z-index: 100;
  padding: 1rem;
  align-items: center !important;
}
.mainContainer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.formRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.custom-select {
  width: 13.5rem !important;
}
.form-control {
  padding: 9px 9px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  max-width: 22.6rem;
  font-size: 1rem;
}
.labelContainer {
  min-width: 200px;
  margin-right: 10px;
}
.stepForm {
  /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}
.btn {
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.registerForm {
  padding: 1rem;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.meetUpFormContainer {
  padding-bottom: 20px;
}
.logoImg {
  width: 60%;
}
.btn:disabled {
  cursor: not-allowed;
}
.meetupFormHeaderContainer {
  display: flex;
  display: flex;
  align-items: center;
  width: 99vw;
}

.headingTextContainer {
  display: flex;
  align-items: center;
  width: inherit;
  text-align: center;
  justify-content: center;
}
.logoContainer {
  display: flex;
  justify-content: center;
}
.btn:disabled {
  opacity: 0.65;
}
textarea.form-control {
  min-width: 90%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.25rem;
  height: 200px;
  overflow: auto;
  resize: vertical;
  color: #000;
  background: #fcfcfc;
  border: 1px solid #c4c4c4;
  font-size: 16px;
  padding: 12px;
  margin: 20px 0;
}
.buttonContainer {
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-wrap: wrap-reverse;
}
.buttonContainer button {
  flex-grow: 1;
  /* width: 25%; */
  font-size: 20px;
  cursor: pointer;
  max-width: 25%;
  margin: 25px;
}
.inputContainer {
  /* min-width: calc(100vw - 500px); */
}

textarea.form-control.mx-3:active {
  background: #fff;
  color: #000;
}
textarea.form-control.mx-3:focus {
  background: #fff;
  color: #000;
}
.registerFormHeading {
  font-size: 1.95rem;
  font-weight: 500;
  line-height: 1.2;
  margin-left: 20px;
  max-width: 100%;
}
.text-success {
  color: #28a745 !important;
}
</style>